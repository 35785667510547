import { Badge } from "@shopify/polaris"

export const BulkStatusBadge = ({ status }) => {
    switch (status) {
        case 'CANCELED':
            return <Badge progress="complete" status="warning">Canceled</Badge>;
        case 'CANCELING':
            return <Badge progress="partiallyComplete" status="warning">Canceling</Badge>;
        case 'COMPLETED':
            return (<Badge progress="complete" status="success">Completed</Badge>);
        case 'CREATED':
            return <Badge progress="incomplete" status="info">Created</Badge>;
        case 'EXPIRED':
            return <Badge progress="incomplete" status="warning">Expired</Badge>;
        case 'FAILED':
            return <Badge progress="complete" status="critical">Failed</Badge>;
        case 'RUNNING':
            return <Badge progress="partiallyComplete" status="warning">Running</Badge>;
        default:
            return null;
    }
}