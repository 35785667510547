import {
    AppProvider,
    Card,
    SkeletonPage,
    Layout,
    SkeletonBodyText,
    TextContainer,
    SkeletonDisplayText
} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

export const LoadingSkeleton = () => (
    <AppProvider i18n={enTranslations}>
        <SkeletonPage>
            <Layout>
                <Layout.Section>
                    <Card>
                        <Card.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={1} />
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={1} />
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={1} />
                            </TextContainer>
                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card>
                        <Card.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                    </Card>
                    <Card subdued>
                        <Card.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={2} />
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
        </SkeletonPage>
    </AppProvider>
)