import { Button, Popover, ActionList, Inline, Banner, Box } from '@shopify/polaris';
import { request } from '../fetch';
import { useState, useCallback } from 'react';

export const TriggerBackup = ({ loadBackups }) => {
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState('');

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const handleTriggerBackup = async (type) => {
        setLoading(true)
        toggleActive()

        try {
            const json = await request(`/api/create-bulk/${type}`, {
                method: 'post',
            });

            setToast({
                type: 'success',
                title: 'Backup Started',
                message: 'Depends on the amount of objects, backup could take up to 2 hours.'
            })
            setLoading(false)

            
            await loadBackups()
        } catch (e) {
            setToast({
                type: 'failed',
                title: 'Backup Failed',
                message: 'Shopify allows only to run one backup at the same time. You have to finish when other backups when be done, in order to run a new one.'
            })
            setLoading(false)

            await loadBackups()
        }
    }

    const activator = (
        <Button onClick={toggleActive} disclosure primary loading={loading}>
            Trigger Backup {loading && 'Loading'}
        </Button>
    )

    return (
        <>
            <Box paddingBlockEnd="3">
                <Inline align="end">
                    <Popover
                        active={active}
                        activator={activator}
                        onClose={toggleActive}
                    >
                        <ActionList
                            actionRole="menuitem"
                            items={[
                                {
                                    content: 'Products',
                                    onAction: () => handleTriggerBackup('products'),
                                },
                                {
                                    content: 'Collections',
                                    onAction: () => handleTriggerBackup('collections'),
                                },
                            ]}
                        />
                    </Popover>
                </Inline>
            </Box>
            <Box paddingBlockEnd="3">
                {!!toast && (
                    <Banner title={toast.title} status={toast.type} onDismiss={() => setToast('')}>
                        <p>{toast.message}</p>
                    </Banner>
                )}
            </Box>
        </>
    )
}