import { useEffect, useState } from 'react';
import { request } from './fetch';
import {
    AppProvider,
    Page,
    Card,
    Layout,
    SkeletonBodyText,
    TextContainer,
    SkeletonDisplayText,
    ResourceList,
    ResourceItem,
    Grid,
    Button,
    Text,
    Box,
    Badge,
    Stack,
    List,
    CalloutCard,
} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import { TriggerBackup } from './components/TriggerBackup';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { BulkStatusBadge } from './components/BulkStatusBadge';
import { BulkList } from './components/BulkList';

const { REACT_APP_PROXY = '' } = process.env;

function App() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [bulks, setBulks] = useState(null);

    async function getUser() {
        setLoading(true);
        setUser(null);

        try {
            const json = await request('/api/user');

            if (json.data?.platformUserId) {
                setUser(json.data);
            }
        } catch (e) {
            console.error('Could not load user', e);
            setUser(null);
        }



        setLoading(false);
    }

    const loadBackups = async () => {
        setBulks(null)

        try {
            const json = await request('/api/bulks')
            setBulks(json?.bulkRequests || [])
        } catch (e) {
            console.error('Could not load backups', e)
        }
    }

    useEffect(() => {
        if (user) {
            loadBackups()
        }
    }, [user])

    useEffect(() => {
        getUser();
    }, [])

    if (loading) {
        return <LoadingSkeleton />
    }

    if (!user) {
        return (
            <AppProvider i18n={enTranslations}>
                <Page title="B2Storefront Tools - Full Backup">
                    <a href={`${REACT_APP_PROXY}/connect?redirectUrl=${encodeURIComponent(window.location.href)}`}>
                        Connect
                    </a>
                </Page>
            </AppProvider>
        )
    }

    return (
        <AppProvider i18n={enTranslations}>
            <Page
                title="Full Backup with Metafields"
                subtitle="Powered by B2Storefront - Headless eCommerce Storefront"
            >
                <Layout>
                    <Layout.Section>
                        <TriggerBackup loadBackups={loadBackups} />
                        <BulkList loadBackups={loadBackups} bulks={bulks} />
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card title="How it works?">
                            <Card.Section>
                                <Text>
                                    <strong>Full Backup with Metafields</strong> powered by B2Storefront - Headless eCommerce Storefront is an app allowing
                                    to do a manual backups of products & collections with their metafields.
                                </Text>
                                <Text>
                                    The exported data is in JSONL format, which stores each separate record in each line item. In order to recover a backup like this,
                                    you need to have a basic knowledge about programming or use our paid service for recover option.
                                </Text>
                                <Text>
                                    This app is the only one, which do a full backup of metafields!
                                </Text>
                            </Card.Section>

                            <Card.Section subdued title="Step by Step Instruction">
                                <List>
                                    <List.Item>Trigger a backup for Products</List.Item>
                                    <List.Item>Download it when finish</List.Item>
                                    <List.Item>Trigger a backup for Collections</List.Item>
                                    <List.Item>Download it when finish</List.Item>
                                    <List.Item>Save these files securely as Shopify removes these backups in 7 days</List.Item>
                                </List>
                            </Card.Section>
                        </Card>
                        <CalloutCard
                            title="Emergency Rollback"
                            illustration="https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/announcements/meta-messenger-illustration-ffa16bde9f5f0cb57478db1280efedcbbb89c4a160c5e816d9edf6fb68b80f91.svg"
                            primaryAction={{
                                content: 'Order rollback service for $300',
                                url: 'https://b2storefront.com',
                            }}
                        >
                            <p>Do you need our assist in recovery your products & collections based on your stored backup JSONL files?</p>
                        </CalloutCard>
                    </Layout.Section>
                </Layout>
            </Page>
        </AppProvider>
    )
}

export default App;
