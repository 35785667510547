import { Box, Button, Card, EmptyState, Inline, Pagination, ResourceItem, ResourceList, SkeletonBodyText, SkeletonDisplayText, Stack, Text, TextContainer } from "@shopify/polaris"
import { useState } from "react";
import { BulkStatusBadge } from "./BulkStatusBadge"

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export const BulkList = ({ bulks, loadBackups }) => {
    const [page, setPage] = useState(1)
    const ITEMS_PER_PAGE = 15
    const NUMBER_OF_PAGES = (bulks && bulks.length) ? Math.ceil(bulks.length / ITEMS_PER_PAGE) : 0

    if (bulks === null) {
        return (
            <>
                <Box paddingBlockEnd="3">
                    <Inline align="space-between">
                        <Text variant="headingLg" as="h4">
                            List of Backups
                        </Text>
                        <Inline>
                            <Button outline loading>Refresh</Button>
                            <Pagination
                                hasPrevious={false}
                                onPrevious={() => {}}
                                hasNext={false}
                                onNext={() => {}}
                            />
                        </Inline>
                    </Inline>
                </Box>
                <Card>
                    <Card.Section>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={1} />
                        </TextContainer>
                    </Card.Section>
                    <Card.Section>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={1} />
                        </TextContainer>
                    </Card.Section>
                    <Card.Section>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={1} />
                        </TextContainer>
                    </Card.Section>
                </Card>
            </>
        )
    }

    if (Array.isArray(bulks) && !bulks.length) {
        return (
            <Card sectioned>
                <EmptyState
                    heading="You haven't created any Backup yet"
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                    <p>Click a <strong>Trigger Build</strong> button above to start a first backup.</p>
                </EmptyState>
            </Card>
        )
    }

    return (
        <>
            <Box paddingBlockEnd="3">
                <Inline align="space-between">
                    <Text variant="headingLg" as="h4">
                        List of Backups
                    </Text>
                    <Inline>
                        <Button outline onClick={() => loadBackups()}>Refresh</Button>
                        <Pagination
                            hasPrevious={page > 1}
                            onPrevious={() => {
                                setPage(page - 1)
                            }}
                            hasNext={page < NUMBER_OF_PAGES}
                            onNext={() => {
                                setPage(page + 1)
                            }}
                        />
                    </Inline>
                </Inline>
            </Box>
            <Card>
                <ResourceList
                    resourceName={{ singular: 'backup', plural: 'backups' }}
                    items={bulks.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)}
                    renderItem={(item) => (
                        <ResourceItem
                            name={item.id}
                            url={item.url}
                            verticalAlignment="fill"
                        >
                            <Stack alignment="center" distribution="fill">
                                <Text>
                                    {item.type} ({item.rootObjectCount})
                                </Text>
                                <Text variant="bodySm">
                                    Started: {new Date(item.createdAt).toLocaleString()}<br />
                                    Completed: {item.completedAt ? new Date(item.completedAt).toLocaleString() : '-'}<br />
                                    <strong>Expires: {item.completedAt ? new Date(item.completedAt).addDays(7).toLocaleString() : '-'}</strong>
                                </Text>
                                <BulkStatusBadge status={item.status} />
                                {!!item.url && (
                                    <div style={{ textAlign: "right" }}>
                                        <Button outline url={item.url}>Download</Button>
                                    </div>
                                )}
                                {!item.url && (
                                    <div style={{ textAlign: "right" }}>
                                        <Button outline disabled>Download</Button>
                                    </div>
                                )}
                            </Stack>
                        </ResourceItem>
                    )}
                />
            </Card>
        </>

    )
}